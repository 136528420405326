import React, { useEffect } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.css';
import './foodDeserts.css';
import { BsCash, BsCarFront, BsPeople, BsBuildings, BsMapFill, BsPeopleFill, BsCashStack } from 'react-icons/bs'
import FoodDesertMap from './FoodDesertMap.js'

/* global google */

/* global google */
let isMapInitialized = false;
const foodDesert = [
    {
        name: "Parker Lane",
        tid: "48453002307",
        population: "5270",
        medianIncome: "$53958",
        foodAccess: ["Low Income", "Low Access", "Low Vehicle Access", "Urban"],
        mapUrl: "https://link-to-google-map.com/food-desert",
        route: '/fooddesert-instance-1'

    },
    {
        name: "East Riverside",
        tid: "48453002313",
        population: "1945",
        medianIncome: "$29966",
        foodAccess: ["Low Income", "Low Access", "Urban"],
        mapUrl: "https://link-to-google-map.com/food-desert",
        route: '/fooddesert-instance-2'
    },
    {
        name: "Walnut Creek",
        tid: "48453043600",
        population: "8204",
        medianIncome: "$72917",
        foodAccess: ["Low Income", "Low Access", "Urban", "Low Vehnicle Access"],
        mapUrl: "https://link-to-google-map.com/food-desert",
        route: '/fooddesert-instance-3'
    }
];


const loadGoogleMapsScript = (callback) => {
    if (window.google) {
        callback();
    } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAqSS9vSsn6bYeOGKi8VVhyQfUwM14JnoU&callback=initMap`;
        script.async = true;
        script.defer = true;
        window.initMap = callback; // Assign callback to window.initMap
        document.head.appendChild(script);
    }
};

const FoodDeserts = () => {
    const navigate = useNavigate();
    useEffect(() => {
        loadGoogleMapsScript(() => {
            foodDesert.forEach(desert => {
                const map = new window.google.maps.Map(document.getElementById(`map-${desert.tid}`), {
                    zoom: 10,
                    center: { lat: 30.2672, lng: -97.7431 } // Centered on Austin, TX
                });

                map.data.loadGeoJson('/tl_2024_48_tract.json', null, (features) => {
                    features.forEach((feature) => {
                        if (feature.getProperty('GEOID') === desert.tid) {
                            map.data.overrideStyle(feature, {
                                fillColor: 'blue',
                                strokeWeight: 0.5,
                                fillOpacity: 0.6,
                                strokeColor: 'black',
                            });
                            const bounds = new window.google.maps.LatLngBounds();
                            feature.getGeometry().forEachLatLng((latLng) => {
                                bounds.extend(latLng);
                            });
                            map.fitBounds(bounds);
                        }
                    });
                });
            });
        });
    }, []);

    return (
        <div className="food-desert-locations-page">
            {/* Header Section */}
            <section className="title">
                <div className='title-section'>
                    <h1>Food Deserts</h1>
                    <p>
                        Explore the identified food deserts across Texas, where access to affordable, nutritious food is limited.
                    </p>
                </div>
            </section>


            {/* Grid Section */}
            <section className="grid-section">
                {foodDesert.map((desert) => (
                    <div id="desert-card" key={desert.tid} onClick={() => navigate(desert.route)} style={{ cursor: 'pointer'}}>
                        <h4 className="card-title fw-bold text-center">{desert.name}</h4>

                        <div className="card-body">
                            <FoodDesertMap tractId={desert.tid} />
                            <div className="card-text">
                                <p>
                                    <BsMapFill className="me-2" />
                                    <strong>Name:</strong>{' '}
                                    {desert.name}
                                </p>
                                <p>
                                    <BsPeopleFill className="me-2" />
                                    <strong>Population:</strong>{' '}
                                    {desert.population}
                                </p>
                                <p>
                                    <BsCashStack className="me-2" />
                                    <strong>Median Family Income:</strong>{' '}
                                    {desert.medianIncome}
                                </p>
                                <div className="mt-3">
                                    {desert.foodAccess.map(
                                        (access, index) => (
                                            <Badge
                                                key={index}
                                                bg="primary"
                                                className="me-2 mb-2"
                                            >
                                                {access === 'Low Income' && (
                                                    <BsCash className="me-1" />
                                                )}
                                                {access === 'Low Access' && (
                                                    <BsPeople className="me-1" />
                                                )}
                                                {access === 'Low Vehicle Access' && (
                                                    <BsCarFront className="me-1" />
                                                )}
                                                {access === 'Urban' && (
                                                    <BsBuildings className="me-1" />
                                                )}
                                                {access}
                                            </Badge>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                ))}
            </section>
            <div className="footer text center">
                Showing 1-3 of 3 instances
            </div>
        </div>
    );
};

export default FoodDeserts;