import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './serviceProviders.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';

const ServiceProviders = () => {
    const navigate = useNavigate();
    return (
        <div className="food-desert-locations-page">
            {/* Header Section */}
                    <section className="title">
                    <div className='title-section'>
                        <h1>Service Providers</h1>
                        <p>
                            Explore the identified service providers across Texas that provides access to affordable, nutritious food.
                        </p>
                    </div>
                </section>


            {/* Grid Section */}
            <section className="grid-section">

                            <div className="card" id="service-card" onClick={() => navigate('/service-providers-instance-1')} style={{ cursor: 'pointer'}}>
                                <h5 className="card-title">Trinity Center</h5>

                                <div className="card-body">
                                    <img className="card-img-top" src="/images/trinity_center_logo.png" alt="Card image cap" />
                                    <p className="card-text">
                                        <strong>Services Offered:</strong> Food Pantry<br />
	    				<strong>Amenities:</strong> Groceries, Hot Meal<br />
                                        <strong>Contact Info:</strong> 512-610-3500<br />
	    				<strong><a href="http://trinitycenteraustin.org/" target="_blank">Website</a></strong><br />
	    				<strong>Service Hours:</strong> Friday 9am for first 80 people, Sunday 3pm for first 65 people<br />
                                        <strong>Address:</strong> 304 E. 7th Austin, TX 78701<br />
                                    </p>
                                </div>
                            </div>

                            <div className="card" id="service-card" onClick={() => navigate('/service-providers-instance-2')} style={{ cursor: 'pointer'}}>
                                <h5 className="card-title">HHSC Benefits Office</h5>

                                <div className="card-body">
                                    <img className="card-img-top" src="https://logos-download.com/wp-content/uploads/2019/01/Texas_Health_and_Human_Services_Logo.png" alt="Card image cap" />
                                    <p className="card-text">
                                        <strong>Services Offered:</strong> Medicaid for families and children, Cash Help for Families(TANF)<br />
                                        <strong>Amenities:</strong> SNAP food benefits<br />
                                        <strong>Contact Info:</strong> 512-4455-0022<br />
                                        <strong><a href="https://www.hhs.texas.gov/" target="_blank">Website</a></strong><br />
	    				<strong>Service Hours:</strong> Mon-Fri, 8 AM - 5 PM<br />
                                        <strong>Address:</strong> 7701 Metropolis Dr, Austin, TX 78744<br />
                                    </p>
                                </div>
                            </div>

                            <div className="card" id="service-card" onClick={() => navigate('/service-providers-instance-3')} style={{ cursor: 'pointer'}}>
                                <h5 className="card-title">St. John Community Center</h5>

                                <div className="card-body">
                                    <img className="card-img-top" src="https://integralcare.org/wp-content/uploads/2019/10/city-of-austin-seal.png" alt="Card image cap" />
                                    <p className="card-text">
                                        <strong>Services Offered:</strong> Senior Program, Food Pantry<br />
                                        <strong>Amenities:</strong> Groceries<br />
                                        <strong>Contact Info:</strong> 512-972-5159<br />
                                        <strong><a href="https://www.austintexas.gov/department/st-john-community-center" target="_blank">Website</a></strong><br />
	    				<strong>Service Hours:</strong> Mon-Thu, 7:30 AM - 5:00 PM, Fri, 8:00 AM - 12:00 PM<br />
                                        <strong>Address:</strong> 7500 Blessing Avenue Austin, TX 78752<br />
                                    </p>
                                </div>
                            </div>

            </section>
            <div className = "footer">
                Showing 1-3 of 3 instances
            </div>
        </div>
    );
};

export default ServiceProviders;
