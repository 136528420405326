import React, { useEffect } from 'react';

/* global google */
const FoodDesertMap = ({ tractId, center = { lat: 30.2672, lng: -97.7431 }, height = '225px' }) => {
    useEffect(() => {
        function initMap() {
            if (typeof google === 'undefined' || !google.maps) {
                console.error('Google Maps API is not loaded.');
                return;
            }

            var map = new google.maps.Map(document.getElementById(`map-${tractId}`), {
                zoom: 10,
                center: center,
            });

            // Load GeoJSON data
            map.data.loadGeoJson('/tl_2024_48_tract.json', null, (features) => {
                if (features.length === 0) {
                    console.error('No features found in the GeoJSON file.');
                    return;
                }

                // Highlight the specific tract
                features.forEach((feature) => {
                    if (feature.getProperty('GEOID') === tractId) {
                        map.data.overrideStyle(feature, {
                            fillColor: 'blue',
                            strokeWeight: 0.5,
                            fillOpacity: 0.6,
                            strokeColor: 'black',
                        });

                        const bounds = new google.maps.LatLngBounds();
                        feature.getGeometry().forEachLatLng((latLng) => {
                            bounds.extend(latLng);
                        });
                        map.fitBounds(bounds);
                    }
                });
            });

            map.data.setStyle({
                fillColor: 'blue',
                strokeWeight: 1,
                fillOpacity: 0.2,
                strokeColor: 'black',
            });

            map.data.addListener('click', function (event) {
                var tractId = event.feature.getProperty('GEOID');
                var tractName = event.feature.getProperty('NAME');
                alert('Census Tract: ' + tractName + '\nGEOID: ' + tractId);
            });
        }

        const loadGoogleMapsScript = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAqSS9vSsn6bYeOGKi8VVhyQfUwM14JnoU&callback=initMap`;
                script.async = true;
                script.defer = true;
                script.id = 'google-maps-script';
                document.head.appendChild(script);
                script.onload = initMap;
            } else {
                initMap(); // Initialize if the script is already loaded
            }
        };

        loadGoogleMapsScript();
    }, [tractId, center]);

    return <div id={`map-${tractId}`} style={{ width: '100%', height: height }} className="rounded"></div>;
};

export default FoodDesertMap;
