import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FoodResources.css';
// import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';

const FoodResources = () => {
    const navigate = useNavigate();
    return (
        <div className="food-desert-locations-page">
            {/* Header Section */}
                    <section className="title">
                    <div className='title-section'>
                        <h1>Food Resources</h1>
                        <p>
                            Explore the different supermarkets near you to find the best places to shop.
                        </p>
                    </div>
                </section>


            {/* Grid Section */}
            <section className="grid-section">

                            <div className="card" id="service-card"  onClick={() => navigate('/foodresource-instance-1')} style={{ cursor: 'pointer'}}>
                                <h5 className="card-title">Walmart Supercenter 1253</h5>

                                <div className="card-body">
                                    <img className="card-img-top" src="/images/walmart.png" alt="Card image cap" />
                                    <p className="card-text">
                                        <strong>Resource Type:</strong> Super Store/ Chain Store<br />
	    				                <strong>Food Inventory:</strong> Fresh Produce, Frozen Foods, Bakery Items<br />
                                        <strong>Payment Options:</strong> SNAP, WIC, Credit Card<br />
                                        <strong>Delivery Options:</strong> In-store Pickup, Home Delivery<br />
	    				                <strong>Service Hours:</strong> Mon-Sun, 6 AM - 11 PM<br />
                                        <strong>Address:</strong> 710 E BEN WHITE BLVD, Austin, TX 78704<br />
                                    </p>
                                </div>
                            </div>

                            <div className="card" id="service-card" onClick={() => navigate('/foodresource-instance-2')} style={{ cursor: 'pointer'}}>
                                <h5 className="card-title">SFC Farmer's Market Downtown</h5>

                                <div className="card-body">
                                    <img className="card-img-top" src="/images/sfc.png" alt="Card image cap" />
                                    <p className="card-text">
                                        <strong>Resource Type:</strong> Farmer's Market<br />
                                        <strong>Food Inventory:</strong> Farm to Table, Individual Vendors<br />
                                        <strong>Payment Options:</strong> SNAP, WIC, Credit Card<br />
                                        <strong>Delivery Options:</strong> No Delivery Options<br />
	    				                <strong>Service Hours:</strong> Sat, 9 AM - 1 PM<br />
                                        <strong>Address:</strong> 422 Guadalupe St, Austin, TX 78701<br />
                                    </p>
                                </div>
                            </div>

                            <div className="card" id="service-card" onClick={() => navigate('/foodresource-instance-3')} style={{ cursor: 'pointer'}}>
                                <h5 className="card-title">99 Ranch Market</h5>

                                <div className="card-body">
                                    <img className="card-img-top" src="images/99ranch.png" alt="Card image cap" />
                                    <p className="card-text">
                                        <strong>Resource Type:</strong> SuperMarket<br />
                                        <strong>Food Inventory:</strong> Fresh Produce, Frozen Foods, Bakery Items<br />
                                        <strong>Payment Options:</strong> Credit Card<br />
                                        <strong>Delivery Options:</strong> In-store Pickup, Home Delivery, Shipping<br />
	    				                <strong>Service Hours:</strong> Sun-Wed, 9 AM - 9 PM, Fri-Sat, 9 AM - 10PM <br />
                                        <strong>Address:</strong> 6929 Airport Blvd, Austin, TX 78752<br />
                                    </p>
                                </div>
                            </div>

            </section>
            <div className = "footer">
                Showing 1-3 of 3 instances
            </div>
        </div>
    );
};

export default FoodResources;
