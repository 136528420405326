import React, { useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Badge,
    Image,
} from 'react-bootstrap';
import {
    BsTelephoneFill,
    BsGeoAltFill,
    BsTruck,
    BsPeopleFill,
    BsCashStack,
    BsCash,
    BsPeople,
    BsCarFront,
    BsBuildings,
    BsBriefcaseFill,
    BsGraphDown,
    BsCurrencyDollar,
    BsClipboardCheck
} from 'react-icons/bs';

import { FaShoppingBasket, FaChartBar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
/* global google */  // Inform ESLint that 'google' is a global variable
const resource = {
    name: "Walmart Supercenter 1253",
    type: "Super Store / Chain Store",
    address: {
        street: "710 E BEN WHITE BLVD",
        city: "Austin",
        state: "TX",
        zip: "78704"
    },
    operatingHours: "Mon-Sun, 6 AM - 11 PM",
    acceptedPayments: ["SNAP", "WIC", "Credit Card"],
    deliveryOptions: {
        available: true,  // Converted to boolean for more clarity
        methods: ["In-store Pickup", "Home Delivery"] // Added flexibility for different methods
    },
    foodInventory: [
        { name: "Fresh Produce", category: "Produce" },
        { name: "Frozen Foods", category: "Frozen" },
        { name: "Bakery Items", category: "Bakery" }
    ],
    logo: "https://1000logos.net/wp-content/uploads/2017/05/Walmart-Logo-768x432.png",
    contactInfo: {
        phone: "1-800-925-6278",  // Added contact info for phone number
        email: "contact@walmart.com",  // Added email
        socialMedia: [
            { platform: "Facebook", postUrl: "https://facebook.com/Walmart1253" },
            { platform: "Twitter", postUrl: "https://twitter.com/Walmart" }
        ]
    },
    connections: {
        nearestFoodDesert: {
            name: "Parker Lane",
            tid: "48453002307",
            population: "5270",
            medianIncome: "$53958",
            foodAccess: ["Low Income", "Low Access", "Low Vehicle Access", "Urban"],
            mapUrl: "https://link-to-google-map.com/food-desert",
        },
        nearestServiceProviders: [
            {
                name: "HHSC Benefits office",
                type: "SNAP Enrollment Center",
                website: "https://austinfoodbank.org",
                mapUrl: "https://link-to-google-map.com/food-bank",
                phone: "512-929-7330",
                photoUrl: "https://logos-download.com/wp-content/uploads/2019/01/Texas_Health_and_Human_Services_Logo.png",  // Changed key name for clarity
                address: {
                    street: "7701 Metropolis Dr",
                    city: "Austin",
                    state: "TX",
                    zip: "78744"
                },
                distance: "4.3",
                socialMedia: [
                    { platform: "Twitter", postUrl: "https://twitter.com/austin_food_bank/status/123456789" }
                ]
            },
        ]
    }
};

const getFoodAccessIcon = (level) => {
    switch (level) {
        case 'Low Income':
            return <BsCash className="me-1" />;
        case 'Low Access':
            return <BsPeople className="me-1" />;
        case 'Low Vehicle Access':
            return <BsTruck className="me-1" />;
        case 'Urban':
            return <BsBuildings className="me-1" />;
        default:
            return null;
    }
};

const FoodResourceInstance = () => {
    const navigate = useNavigate();
    useEffect(() => {
        function initMap() {
            if (typeof google === 'undefined' || !google.maps) {
                console.error('Google Maps API is not loaded.');
                return;
            }

            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: { lat: 30.2672, lng: -97.7431 }, 
            });

            // Load GeoJSON data
            map.data.loadGeoJson('/tl_2019_48_tract.json', null, (features) => {
                if (features.length === 0) {
                    console.error('No features found in the GeoJSON file.');
                } else {
                    console.log('GeoJSON loaded:', features);

                    // Highlight the specific tract
                    features.forEach((feature) => {
                        if (
                            feature.getProperty('GEOID') ===
                            resource.connections.nearestFoodDesert.tid
                        ) {
                            map.data.overrideStyle(feature, {
                                fillColor: 'blue',
                                strokeWeight: 2,
                                fillOpacity: 0.6,
                                strokeColor: 'black',
                            });

                            const bounds = new google.maps.LatLngBounds();
                            feature.getGeometry().forEachLatLng((latLng) => {
                                bounds.extend(latLng);
                            });
                            map.fitBounds(bounds);
                        } else {
                            // map.data.overrideStyle(feature, { visible: false });
                        }
                    });
                }
            });

            map.data.setStyle({
                fillColor: 'blue',
                strokeWeight: 1,
                fillOpacity: 0.2,
                strokeColor: 'black',
            });

            map.data.addListener('click', function (event) {
                var tractId = event.feature.getProperty('GEOID');
                var tractName = event.feature.getProperty('NAME');
                alert('Census Tract: ' + tractName + '\nGEOID: ' + tractId);
            });
        }

        const loadGoogleMapsScript = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAqSS9vSsn6bYeOGKi8VVhyQfUwM14JnoU&callback=initMap`;
            script.async = true;
            script.defer = true;
            window.initMap = initMap; // Assign initMap as a global function
            document.head.appendChild(script);
        };

        loadGoogleMapsScript();
    }, [resource]);

    return (
        <Container className="my-5">
            <Row>
                {/* Left Column: Basic Info, Map, and Food Desert */}
                <Col md={8} className="align-items-center">
                    {/* Card for basic info */}
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Row className="align-items-center">

                                <div
                                    id="map"
                                    style={{ height: '225px' }}
                                    className="rounded"
                                ></div>

                            </Row >
                            <Row className="align-items-center">
                                {/* Inner Card for Text Content */}

                                <Card className="shadow">
                                    <Card.Body>
                                        <Row>
                                            <h2 className="fw-bolder text-center">Parker Lane</h2>
                                            {/* Left Column: Demographics */}



                                            <Col md={6} className="demographics-section">
                                                <Card className="shadow p-3 ">
                                                    <h4 className="card-title">Demographics</h4>
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsGeoAltFill className="me-1 icon-color" />
                                                        <span>
                                                            <strong>Austin TX, Travis County</strong>
                                                        </span>
                                                    </div>
                                                    {/* Location */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsGeoAltFill className="me-1 icon-color" />
                                                        <span>
                                                            <strong>Tract ID:</strong> 48453002307
                                                        </span>
                                                    </div>
                                                    {/* Population */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsPeopleFill className="me-1 icon-color" />
                                                        <span>
                                                            <strong>Population:</strong> 5270
                                                        </span>
                                                    </div>
                                                    {/* Square Mile */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <FaChartBar className="me-1 icon-color" />
                                                        <span>
                                                            <strong>Square Mile:</strong> 0.8
                                                        </span>
                                                    </div>
                                                    {/* Population Density */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsGraphDown className="me-1 icon-color" />
                                                        <span>
                                                            <strong>Population Density:</strong> 5919.3 per sq mi
                                                        </span>
                                                    </div>
                                                    <iframe id="cr-embed-14000US48453002307-economics-poverty-children" className="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=14000US48453002307&chartDataID=economics-poverty-children&dataYear=2022&releaseID=ACS_2022_5-year&chartType=pie&chartHeight=200&chartQualifier=&chartTitle=Children+(Under+18)&initialSort=&statType=percentage" frameBorder="0" width="100%" height="300" style={{ margin: '1em'}}>
                                                    </iframe>
                                                    <script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js">
                                                    </script>
                                                </Card>
                                            </Col>

                                            {/* Divider */}


                                            {/* Right Column: Food Access Analytics */}
                                            <Col md={6} className="food-access-section">
                                                <Card className="shadow p-3 ">
                                                    <h4 className="card-title">Food Access Analytics</h4>
                                                    <div className="mb-3">
                                                        <div className="mt-2">
                                                            {resource.connections.nearestFoodDesert.foodAccess.map(
                                                                (access, index) => (
                                                                    <Badge
                                                                        key={index}
                                                                        bg="primary"
                                                                        className="me-2 mb-2"
                                                                    >
                                                                        {access === 'Low Income' && (
                                                                            <BsCash className="me-1" />
                                                                        )}
                                                                        {access === 'Low Access' && (
                                                                            <BsPeople className="me-1" />
                                                                        )}
                                                                        {access === 'Low Vehicle Access' && (
                                                                            <BsCarFront className="me-1" />
                                                                        )}
                                                                        {access === 'Urban' && (
                                                                            <BsBuildings className="me-1" />
                                                                        )}
                                                                        {access}
                                                                    </Badge>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Poverty Rate */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsCashStack className="me-2 icon-color" />
                                                        <span>
                                                            <strong>Poverty Rate:</strong> 24.8%
                                                        </span>
                                                    </div>
                                                    {/* Median Family Income */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsCurrencyDollar className="me-2 icon-color" />
                                                        <span>
                                                            <strong>Median Family Income:</strong> $53958
                                                        </span>
                                                    </div>
                                                    {/* Food Access Level */}


                                                    {/* Number of SNAP Enrollments */}
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <BsClipboardCheck className="me-2 icon-color" />
                                                        <span>
                                                            <strong>Number of SNAP Enrollments:</strong> 494
                                                        </span>
                                                    </div>
                                                    <iframe id="cr-embed-14000US48453002307-economics-income-household_distribution" className="census-reporter-embed" src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/iframe.html?geoID=14000US48453002307&chartDataID=economics-income-household_distribution&dataYear=2022&releaseID=ACS_2022_5-year&chartType=histogram&chartHeight=200&chartQualifier=&chartTitle=Household+income&initialSort=&statType=scaled-percentage" frameBorder="0" width="100%" height="300" style={{ margin: '1em', maxWidth: '100%' }}></iframe>
                                                    <script src="https://s3.amazonaws.com/embed.censusreporter.org/1.0/js/embed.chart.make.js"></script>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Right Column: Contact Info and Service Providers */}
                <Col md={4}>


                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Card.Title as="h4" className="fw-bold mb-2">Nearest Service Providers</Card.Title>
                            {resource.connections.nearestServiceProviders.map(
                                (provider, index) => (
                                    <Card
                                        key={index}
                                        className="mb-3 shadow"
                                        style={{ borderColor: '#dee2e6', borderWidth: '1px', cursor: 'pointer'}}
                                        onClick={() => navigate('/service-providers-instance-2')} 
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                overflow: 'hidden',
                                                borderBottom: '1px solid #dee2e6',
                                            }}
                                        >
                                            {/* Image with fade effect */}
                                            <Image
                                                src={provider.photoUrl}
                                                alt={`${provider.name} photo`}
                                                fluid
                                                className="rounded-top"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover', // Ensures image covers the container
                                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                }}
                                            />
                                            {/* Gradient fade-out effect on the image */}
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    left: '0',
                                                    right: '0',
                                                    height: '40px',
                                                    background:
                                                        'linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.09))',
                                                }}
                                            ></div>
                                        </div>
                                        <Card.Body style={{ backgroundColor: '#f8f9fa' }}>
                                            <Card.Title as="h5">{provider.name}</Card.Title>
                                            <Card.Text>
                                                <BsGeoAltFill className="me-2" />
                                                {`${provider.address.street}, ${provider.address.city}, ${provider.address.state} ${provider.address.zip}`} -{' '}
                                                {provider.distance} miles away
                                            </Card.Text>
                                            <Card.Text>
                                                <BsBriefcaseFill className="me-2" />
                                                {provider.type}
                                            </Card.Text>
                                            <Card.Text>
                                                <BsTelephoneFill className="me-2" />
                                                {provider.phone}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            )}
                        </Card.Body>
                    </Card>
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Card.Title as="h4" className="fw-bold mb-2">Nearest Food Resources</Card.Title>
                            {/* Food Resources Section as its own card */}
                            <Card
                                className="mb-3 shadow"
                                onClick={() => navigate('/foodresource-instance-1')} 
                                style={{ borderColor: '#dee2e6', borderWidth: '1px', cursor: 'pointer' }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderBottom: '1px solid #dee2e6',
                                    }}
                                >
                                    {/* Image with fade effect */}
                                    <Image
                                        src={resource.logo}
                                        alt={`${resource.name} photo`}
                                        fluid
                                        className="rounded-top"
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover', // Ensures image covers the container
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        }}
                                    />
                                    {/* Gradient fade-out effect on the image */}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: '0',
                                            left: '0',
                                            right: '0',
                                            height: '40px',
                                            background:
                                                'linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.09))',
                                        }}
                                    ></div>
                                </div>
                                <Card.Body style={{ backgroundColor: '#f8f9fa' }}>
                                    <Card.Title as="h5">{resource.name}</Card.Title>
                                    <Card.Text>
                                        <BsGeoAltFill className="me-2" />
                                        {`${resource.address.street}, ${resource.address.city}, ${resource.address.state} ${resource.address.zip}`}
                                    </Card.Text>
                                    <Card.Text>
                                        <BsBriefcaseFill className="me-2" />
                                        {resource.type}
                                    </Card.Text>
                                    <Card.Text>
                                        <BsTelephoneFill className="me-2" />
                                        {resource.contactInfo.phone}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FoodResourceInstance;