import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaUtensils, FaMapMarkedAlt, FaHandsHelping } from 'react-icons/fa';
import './Homepage.css';

const Homepage = () => {
    const [showScrollCue, setShowScrollCue] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setShowScrollCue(true); // Reappear when scrolled to the top
            }
        };

        const featuresSection = document.querySelector('#features-section');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setShowScrollCue(false);
                    } else {
                        setShowScrollCue(true); 
                    }
                });
            },
            { threshold: 0.1 } 
        );

        if (featuresSection) {
            observer.observe(featuresSection);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            observer.disconnect();
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {/* Hero Section */}
            <section id="hero-section">
                <Container>
                    <Row className="align-items-center text-center">
                        <Col md={12}>
                            <h1 id="hero-title">The platform that <span id="highlight">empowers</span> Texas communities</h1>
                            <p id="hero-subtitle">We connect Texans with essential food resources and support services to help eliminate food deserts.</p>
                            <Button href="/food-resources" id="hero-button">Explore Resources</Button>
                        </Col>
                    </Row>
                </Container>
                <div className={`scroll-cue ${!showScrollCue ? 'hidden' : ''}`}>
                    <i className="fas fa-chevron-down"></i>
                </div>
            </section>

            <hr className='feature-hr'/>

            {/* Features Section */}
            <section id="features-section">
                <Container>
                    <h2 id="features-section-title"className="text-center">Our Core Services</h2>
                    <Row>
                        <Col md={4} id="features-card-container">
                            <Card className="feature-card">
                                <Card.Body>
                                    <FaMapMarkedAlt className="feature-icon" />
                                    <Card.Title className='feature-card-title'>Food Deserts</Card.Title>
                                    <Card.Text className='feature-card-text'>Explore and learn about food deserts across Texas, helping communities in need.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} id="features-card-container">
                            <Card className="feature-card">
                                <Card.Body>
                                    <FaUtensils className="feature-icon" />
                                    <Card.Title className='feature-card-title'>Food Resources</Card.Title>
                                    <Card.Text className='feature-card-text'>Locate food pantries, grocery stores, and other vital services in your area.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} id="features-card-container">
                            <Card className="feature-card">
                                <Card.Body>
                                    <FaHandsHelping className="feature-icon" />
                                    <Card.Title className='feature-card-title'>Service Providers</Card.Title>
                                    <Card.Text className='feature-card-text'>Connect with service providers who support food security and assistance programs.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr className='feature-hr'/>

            {/* Footer Section */}
            <footer id="footer-section">
                <Container>
                    <Row>
                        <Col md={4} id='footer-col' className='mx-auto'>
                            <h3>Quick Links</h3>
                            <ul className="footer-links">
                                <li><a href="/about-us">About Us</a></li>
                                <li><a href="/food-deserts">Food Deserts</a></li>
                                <li><a href="/food-resources">Food Resources</a></li>
                                <li><a href="/service-providers">Service Providers</a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
};

export default Homepage;
