import React, { useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  ListGroup,
  Image,
} from 'react-bootstrap';
import {
  BsTelephoneFill,
  BsEnvelopeFill,
  BsGeoAltFill,
  BsClockFill,
  BsCreditCardFill,
  BsTruck,
  BsBox2Fill,
  BsMapFill,
  BsPeopleFill,
  BsCashStack,
  BsCash,
  BsPeople,
  BsCarFront,
  BsBuildings,
  BsBriefcaseFill,
  BsFacebook,
  BsTwitter,
  BsInstagram
} from 'react-icons/bs';

import { FaShoppingBasket } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
/* global google */  // Inform ESLint that 'google' is a global variable
const resource = {
    name: "Trinity Center",
    type: "Food Pantry",
    address: {
        street: "304 E 7th Street",
        city: "Austin",
        state: "TX",
        zip: "78701"
    },
    operatingHours: "Mon-Fri, 9 AM - 6 PM, Sun, 3 PM - 4:30 PM",
    acceptedPayments: ["Groceries, Hot Meals"],
    deliveryOptions: {
        available: false,  // Converted to boolean for more clarity
        methods: ["N/A"] // Added flexibility for different methods
    },
    foodInventory: [
        { name: "N/A", category: "N/A" },
    ],
    logo: "/images/trinity_center_logo.png",
    contactInfo: {
        phone: "512-610-3500",  // Added contact info for phone number
        email: "N/A",  // Added email
        socialMedia: [
            { platform: "Facebook", postUrl: "https://www.facebook.com/trinitycenteraustin" },
            { platform: "Instagram", postUrl: "https://www.instagram.com/trinitycenteraustin/" }
        ]
    },
    connections: {
        nearestFoodDesert: {
            name: "East Riverside",
            tid: "48453002313",
            population: "1945",
            medianIncome: "$51175",
            foodAccess: ["Low Income", "Low Access", "Urban"],
            mapUrl: "https://link-to-google-map.com/food-desert"
        },
        nearestServiceProviders: [
            {
                name: "SFC Farmer's Market Downtown",
                type: "Farmer's Market",
                website: "https://austinfoodbank.org",
                mapUrl: "https://link-to-google-map.com/food-bank",
                phone: "512-236-0074",
                photoUrl: "/images/sfc.png",
                address: {
                    street: "442 Guadalupe St",
                    city: "Austin",
                    state: "TX",
                    zip: "78701"
                },
                distance: "4.3",
                socialMedia: [
                    { platform: "Twitter", postUrl: "https://twitter.com/austin_food_bank/status/123456789" }
                ]
            },
        ]
    }
};

const FoodResourceInstance = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Initialize Google Maps with GeoJSON functionality
        function initMap() {
            if (typeof google === 'undefined' || !google.maps) {
                console.error('Google Maps API is not loaded.');
                return;
            }

            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: { lat: 30.2672, lng: -97.7431 }, // Centered on Austin, TX
            });

            // Load GeoJSON data
            map.data.loadGeoJson('/tl_2019_48_tract.json', null, (features) => {
                if (features.length === 0) {
                    console.error('No features found in the GeoJSON file.');
                } else {
                    console.log('GeoJSON loaded:', features);

                    // Highlight the specific tract
                    features.forEach((feature) => {
                        if (
                            feature.getProperty('GEOID') ===
                            resource.connections.nearestFoodDesert.tid
                        ) {
                            map.data.overrideStyle(feature, {
                                fillColor: 'blue',
                                strokeWeight: 2,
                                fillOpacity: 0.6,
                                strokeColor: 'black',
                            });

                            const bounds = new google.maps.LatLngBounds();
                            feature.getGeometry().forEachLatLng((latLng) => {
                                bounds.extend(latLng);
                            });
                            map.fitBounds(bounds);
                        } else {
                            map.data.overrideStyle(feature, { visible: false });
                        }
                    });
                }
            });

            map.data.setStyle({
                fillColor: 'blue',
                strokeWeight: 1,
                fillOpacity: 0.2,
                strokeColor: 'black',
            });

            map.data.addListener('click', function (event) {
                var tractId = event.feature.getProperty('GEOID');
                var tractName = event.feature.getProperty('NAME');
                alert('Census Tract: ' + tractName + '\nGEOID: ' + tractId);
            });
        }

        const loadGoogleMapsScript = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAqSS9vSsn6bYeOGKi8VVhyQfUwM14JnoU&callback=initMap`;
            script.async = true;
            script.defer = true;
            window.initMap = initMap; // Assign initMap as a global function
            document.head.appendChild(script);
        };

        loadGoogleMapsScript();
    }, [resource]);

    return (
        <Container className="my-5">
            <Row>
                {/* Left Column: Basic Info, Map, and Food Desert */}
                <Col md={8}>
                    {/* Card for basic info */}
                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Row className="align-items-center">
                                <Col md={6} className="text-center">
                                    <Image
                                        src={resource.logo}
                                        alt={`${resource.name} logo`}
                                        fluid
                                        className="mb-3"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </Col>
                                <Col md={6}>
                                    {/* Inner Card for Text Content */}
                                    <Card className="shadow-sm p-3 rounded">
                                        <Card.Title as="h2">{resource.name}</Card.Title>
                                        <div className="my-3">
                                            <BsGeoAltFill className="me-2" />
                                            <span>
                                                {`${resource.address.street}, ${resource.address.city}, ${resource.address.state} ${resource.address.zip}`}
                                            </span>
                                        </div>
                                        <div className="my-3">
                                            <FaShoppingBasket className="me-2" />
                                            <span>{resource.type}</span>
                                        </div>
                                        <div className="my-3">
                                            <BsClockFill className="me-2" />
                                            <span>{resource.operatingHours}</span>
                                        </div>
                                        <div className="my-3">
                                            <BsBriefcaseFill className="me-2" />
                                            <span>{resource.acceptedPayments.join(', ')}</span>
                                        </div>
                                       
                                    </Card>
                                </Col>
                            </Row>

                            {/* Map Section */}
                            <div className="mt-4">
                                <Card className="shadow-sm p-3 rounded">
                                    <Card.Title as="h3">Location Map</Card.Title>
                                    <div style={{ width: '100%', height: '400px' }}>
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            frameBorder="0"
                                            style={{ border: 0 }}
                                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAqSS9vSsn6bYeOGKi8VVhyQfUwM14JnoU&q=${encodeURIComponent(
                                                `${resource.address.street}, ${resource.address.city}, ${resource.address.state} ${resource.address.zip}`
                                            )}`}
                                            allowFullScreen
                                            aria-hidden="false"
                                            tabIndex="0"
                                            className="rounded"
                                        ></iframe>
                                    </div>
                                </Card>
                            </div>
                        </Card.Body>
                    </Card>

                    {/* Food Desert Section as its own card */}
                    <Card className="shadow mb-4" onClick={() => navigate('/fooddesert-instance-2')} style={{ cursor: 'pointer'}}>
                        <Card.Body>
                            <Row>
                                {/* Map on the left, occupying the entire left half */}
                                <Col md={6}>
                                    <div
                                        id="map"
                                        style={{ width: '100%', height: '225px' }}
                                        className="rounded"
                                    ></div>
                                </Col>

                                {/* Text and title on the right */}
                                <Col md={6}>
                                    <Card.Title as="h3">Nearest Food Desert</Card.Title>
                                    <p>
                                        <BsMapFill className="me-2" />
                                        <strong>Name:</strong>{' '}
                                        {resource.connections.nearestFoodDesert.name}
                                    </p>
                                    <p>
                                        <BsPeopleFill className="me-2" />
                                        <strong>Population:</strong>{' '}
                                        {resource.connections.nearestFoodDesert.population}
                                    </p>
                                    <p>
                                        <BsCashStack className="me-2" />
                                        <strong>Median Family Income:</strong>{' '}
                                        {resource.connections.nearestFoodDesert.medianIncome}
                                    </p>

                                    {/* Food Access Labels */}
                                    <div className="mt-3">
                                        {resource.connections.nearestFoodDesert.foodAccess.map(
                                            (access, index) => (
                                                <Badge
                                                    key={index}
                                                    bg="primary"
                                                    className="me-2 mb-2"
                                                >
                                                    {access === 'Low Income' && (
                                                        <BsCash className="me-1" />
                                                    )}
                                                    {access === 'Low Access' && (
                                                        <BsPeople className="me-1" />
                                                    )}
                                                    {access === 'Low Vehicle Access' && (
                                                        <BsCarFront className="me-1" />
                                                    )}
                                                    {access === 'Urban' && (
                                                        <BsBuildings className="me-1" />
                                                    )}
                                                    {access}
                                                </Badge>
                                            )
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Right Column: Contact Info and Service Providers */}
                <Col md={4}>
                    <Card className="shadow-sm mb-4">
                        <Card.Body>
                            <Card.Title as="h3">Contact Information</Card.Title>
                            <div className="mb-3">
                                <BsTelephoneFill className="me-2" />
                                <span>{resource.contactInfo.phone}</span>
                            </div>
                            <div className="mb-3">
                                <BsEnvelopeFill className="me-2" />
                                <span>{resource.contactInfo.email}</span>
                            </div>
                            <div className="mt-4">
                                <Card.Title as="h4">Social Media</Card.Title>
                                <ListGroup>
                                    {resource.contactInfo.socialMedia.map((post, index) => (
                                        <ListGroup.Item key={index}>
                                            <a
                                                href={post.postUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {post.platform === 'Facebook' && (
                                                    <BsFacebook className="fs-2 text-primary me-2" />
                                                )}
                                                {post.platform === 'Twitter' && (
                                                    <BsTwitter className="fs-2 text-primary me-2" />
                                                )}
                                                {post.platform === 'Instagram' && (
                                                    <BsInstagram className="fs-2 text-primary me-2"/>
                                                )}
                                                {post.platform}
                                            </a>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="shadow mb-4">
                        <Card.Body>
                            <Card.Title as="h3">Nearest Food Resources</Card.Title>
                            {resource.connections.nearestServiceProviders.map(
                                (provider, index) => (
                                    <Card
                                        key={index}
                                        className="mb-3 shadow"
                                        onClick={() => navigate('/foodresource-instance-2')} 
                                        style={{ borderColor: '#dee2e6', borderWidth: '1px', cursor: 'pointer'}}
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                overflow: 'hidden',
                                                borderBottom: '1px solid #dee2e6',
                                            }}
                                        >
                                            {/* Image with fade effect */}
                                            <Image
                                                src={provider.photoUrl}
                                                alt={`${provider.name} photo`}
                                                fluid
                                                className="rounded-top"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover', // Ensures image covers the container
                                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                }}
                                            />
                                            {/* Gradient fade-out effect on the image */}
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    left: '0',
                                                    right: '0',
                                                    height: '40px',
                                                    background:
                                                        'linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.09))',
                                                }}
                                            ></div>
                                        </div>
                                        <Card.Body style={{ backgroundColor: '#f8f9fa' }}>
                                            <Card.Title as="h5">{provider.name}</Card.Title>
                                            <Card.Text>
                                                <BsGeoAltFill className="me-2" />
                                                {`${provider.address.street}, ${provider.address.city}, ${provider.address.state} ${provider.address.zip}`} -{' '}
                                                {provider.distance} miles away
                                            </Card.Text>
                                            <Card.Text>
                                                <BsBriefcaseFill className="me-2" />
                                                {provider.type}
                                            </Card.Text>
                                            <Card.Text>
                                                <BsTelephoneFill className="me-2" />
                                                {provider.phone}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FoodResourceInstance;
