import React from 'react';
import GlobalNavbar from './components/navbar/GlobalNavbar';
import AboutPage from './components/about/AboutPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/homepage/Homepage';
import FoodDeserts from './components/foodDeserts/foodDeserts';
import FoodResources from './components/FoodResources/FoodResources';
import ServiceProviders from './components/serviceProviders/serviceProviders';
import FoodResourceInstance_1 from './components/FoodResourceInstances/FoodResourceInstance_1'
import FoodResourceInstance_2 from './components/FoodResourceInstances/FoodResourceInstance_2'
import FoodResourceInstance_3 from './components/FoodResourceInstances/FoodResourceInstance_3'
import FoodDesertInstance_1 from './components/FoodDesertInstance/FoodDesertInstance_1'
import FoodDesertInstance_2 from './components/FoodDesertInstance/FoodDesertInstance_2'
import FoodDesertInstance_3 from './components/FoodDesertInstance/FoodDesertInstance_3'
import ServiceProvidersInstance_1 from './components/serviceProvidersInstance/serviceProvidersInstance_1'
import ServiceProvidersInstance_2 from './components/serviceProvidersInstance/serviceProvidersInstance_2'
import ServiceProvidersInstance_3 from './components/serviceProvidersInstance/serviceProvidersInstance_3'
// import 'bootstrap/dist/css/bootstrap.min.css'

// Placeholder components for other tabs
// const ServiceProviders = () => <h2>Service Providers Page</h2>;

function App() {
  return (
    <Router>
    <div className="App" id="App">
      <GlobalNavbar />
        <div style={{ paddingTop: '70px' }} id="content">
          <Routes>
            <Route path="/" element={<Homepage/>} id="homepage"/>
            <Route path="/about-us" element={<AboutPage />} />
            <Route path="/food-deserts" element={<FoodDeserts />} />
            <Route path="/food-resources" element={<FoodResources />} />
            <Route path="/service-providers" element={<ServiceProviders />} />
	    // instances for food resrouces
	    <Route path="/foodresource-instance-1" element={<FoodResourceInstance_1 />} />
      <Route path="/foodresource-instance-2" element={<FoodResourceInstance_2 />} />
      <Route path="/foodresource-instance-3" element={<FoodResourceInstance_3 />} />
	    // instances for food deserts
	    <Route path="/fooddesert-instance-1" element={<FoodDesertInstance_1 />} />
	    <Route path="/fooddesert-instance-2" element={<FoodDesertInstance_2 />} />
	    <Route path="/fooddesert-instance-3" element={<FoodDesertInstance_3 />} />
	    // instances for service providers
	    <Route path="/service-providers-instance-1" element={<ServiceProvidersInstance_1 />} />
	    <Route path="/service-providers-instance-2" element={<ServiceProvidersInstance_2 />} />
	    <Route path="/service-providers-instance-3" element={<ServiceProvidersInstance_3 />} />
          </Routes>
        </div>
    </div>
    </Router>
  );
}

export default App;
