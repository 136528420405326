import React, { useState, useEffect } from 'react';
import './GlobalNavbar.css';

const GlobalNavbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`} id="mainNavBar">
      <a href="/" className="brand">FOOD OASIS TEXAS</a>
      <div className="nav-links">
        <a href="/about-us">About Us</a>
        <a href="/food-deserts">Food Deserts</a>
        <a href="/food-resources">Food Resources</a>
        <a href="/service-providers">Service Providers</a>
      </div>
    </nav>
  );
};

export default GlobalNavbar;